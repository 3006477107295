// note: it is necessary to have the colorClass written here in full so
// the tailwind JIT compiler can pick it up
// (not possible to extrapolate strings like `bg-${color}`)

export const statusMapping = {
  pending_delivery_tracking_information: {
    colorClass: 'bg-[#D1E5FF]',
    hoverableColorClass: 'hover:bg-[#aacfff]',
    french: "en attente d'envoi",
  },
  in_delivery_shipping: {
    colorClass: 'bg-[#B9F0B9]',
    hoverableColorClass: 'hover:bg-[#A6D6A6]',
    french: 'en livraison',
  },
  in_trial: {
    colorClass: 'bg-[#FFE066]',
    hoverableColorClass: 'hover:bg-[#FFCB00]',
    french: "en période d'essai",
  },
  pending_dropoff: {
    colorClass: 'bg-[#C6CFFF]',
    hoverableColorClass: 'hover:bg-[#A8B6FF]',
    french: 'attente dépôt client',
  },
  in_return_shipping: {
    colorClass: 'bg-[#BAE8F2]',
    hoverableColorClass: 'hover:bg-[#7de4fa]',
    french: 'retour livraison',
  },
  pending_return_control: {
    colorClass: 'bg-[#FFBA69]',
    hoverableColorClass: 'hover:bg-[#FFA63D]',
    french: 'Attente du contrôle',
  },
  closed: {
    colorClass: 'bg-iconText !text-white',
    hoverableColorClass: 'hover:bg-[#828382]',
    french: 'Terminée',
  },
  canceled_by_admin: {
    colorClass: 'bg-[#EAEAEA]',
    hoverableColorClass: 'hover:bg-[#D4D4D4]',
    french: 'Annulée',
  },
}

export const possibleRejectionReasons = [
  [1, 'Article t\u00e2ch\u00e9'],
  [2, 'Article d\u00e9chir\u00e9'],
  [3, 'Article tr\u00e8s endommag\u00e9'],
  [4, 'Article avec \u00e9tiquette manquante'],
  [5, 'Autre'],
]
