<script lang="ts" setup>
  const {isBig} = defineProps<{isBig?: boolean}>()

  const width = isBig ? 150 : 43
  const height = isBig ? 87 : 25
</script>

<template>
  <div class="icon relative">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 43 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5396 15.8443C9.96848 17.0893 8.74838 18.1008 6.82737 18.1008C4.72464 18.1008 3.14111 16.7521 2.54404 14.5215L9.73485 12.1613C10.5136 11.9019 10.9549 11.461 10.9549 10.631C10.9549 8.84137 9.24161 6.79237 6.12646 6.79237C2.67384 6.79237 0 9.51573 0 13.1728C0 17.219 2.67384 19.7608 6.02262 19.7608C9.26757 19.7608 10.9809 17.608 11.2924 16.0518C11.3703 15.559 10.7732 15.4812 10.5396 15.8443ZM5.73707 7.62234C7.52828 7.62234 8.77434 9.51573 8.77434 10.6829C8.77434 11.1238 8.59262 11.4091 8.15131 11.5907L2.36232 13.6656C2.28444 13.2506 2.25848 12.8097 2.25848 12.3428C2.25848 9.69729 3.63434 7.62234 5.73707 7.62234Z"
        fill="white"
      />
      <path
        d="M12.4117 19.5014H15.6048C15.9163 19.5014 16.1239 19.3717 16.1239 19.1642V19.0864C16.1239 18.5677 15.1115 18.5936 15.1115 17.6858V0.463785C15.1115 0.126607 14.826 -0.158697 14.3068 0.10067L12.2819 1.08627C12.0483 1.21595 11.9185 1.29376 11.9185 1.50126V1.55313C11.9185 1.94218 12.8271 2.0978 12.8271 3.10934V17.6858C12.8271 18.5936 11.9185 18.5677 11.9185 19.0864V19.1642C11.9185 19.3717 12.1002 19.5014 12.4117 19.5014Z"
        fill="white"
      />
      <path
        d="M29.1901 7.05173H26.5942C26.3605 7.05173 26.2048 7.18142 26.2048 7.36297V7.44078C26.2048 7.95952 27.7624 7.93358 27.1912 9.25636L23.479 17.8414L19.7408 9.04887C19.2476 7.93358 20.1822 7.95952 20.1822 7.44078V7.36297C20.1822 7.18142 20.0264 7.05173 19.8187 7.05173H16.444C16.2363 7.05173 16.0546 7.15548 16.0546 7.36297V7.44078C16.0546 7.95952 16.7815 7.93358 17.3006 9.02293L22.2849 19.9942C21.3763 22.2766 20.2341 24.0922 19.1178 24.0922C17.5083 24.0922 17.8718 21.6542 16.5219 21.6542C15.8988 21.6542 15.5354 22.0951 15.5354 22.6916C15.5354 23.7032 16.7815 25 18.7284 25C21.2725 25 22.4666 22.1469 23.479 19.8645L28.3854 9.04887C28.8786 7.93358 29.5536 7.95952 29.5536 7.44078V7.36297C29.5536 7.15548 29.3978 7.05173 29.1901 7.05173Z"
        fill="white"
      />
      <path
        d="M41.8963 17.6339L41.6108 12.5244C41.3512 8.50419 39.2225 6.79237 36.6785 6.79237C34.8872 6.79237 33.3816 7.72609 32.6288 9.17855V7.28516C32.6288 6.89611 32.3951 6.63674 31.9019 6.89611L29.9549 7.82984C29.6953 7.93358 29.5396 8.03733 29.5396 8.21889V8.2967C29.5396 8.76356 30.6299 8.71169 30.6299 9.82697V17.6858C30.6299 18.5936 29.6175 18.5936 29.6175 19.0864V19.1902C29.6175 19.3976 29.8251 19.5273 30.1366 19.5273H33.3816C33.7191 19.5273 33.9267 19.3976 33.9267 19.1902V19.0864C33.9267 18.5936 32.8884 18.5936 32.8884 17.6858V11.3832C32.8884 9.33417 34.2123 7.8039 36.1333 7.8039C37.9245 7.8039 39.1965 9.30824 39.3263 12.4725L39.5859 17.6599C39.6379 18.5936 38.6514 18.5936 38.6514 19.0864V19.1902C38.6514 19.3976 38.8591 19.5273 39.1965 19.5273H42.2857C42.6232 19.5273 42.8309 19.3976 42.8309 19.1902V19.0864C42.8309 18.5936 41.9483 18.5936 41.8963 17.6339Z"
        fill="white"
      />
    </svg>
    <!-- <span class="text-lg absolute -top-2 left-11">🐝</span> -->
  </div>
</template>
